<template>
    <div class="container-fluid">
        <div class="sticky-container">
            <ul class="sticky">
                <li>
                    <img :src="whatsappLogo" width="50" height="50" alt="Package Disabler Whatsapp Contact Us">
                    <p><a href="https://api.whatsapp.com/send/?phone=447449294718&text=Please contact us on WhatsApp for a faster reply." target="_blank">Contact us <br>on Whatsapp</a></p>
                </li>
            </ul>
        </div>
        <div class="container-fluid1">
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-4 block-1">
                    <div>
                        <h4><b>{{ planDetail.name }}</b></h4><hr>
                        <p class="dis my-3 info fst-normal color-white">{{ planDetail.description }}</p>
                        <h4 class="">Summary</h4><hr>
                        <ul :style=" { 'list-style-type': 'none' }">
                            <li><span class="color-white">Plan Price</span> <span class="float-right color-black"><b> {{ formatCurrency(planDetail.amount, planDetail.currencyCode) }} <span v-if="planDetail.type == 'recurring'"> / {{ planDetail.interval }}</span></b></span> </li>
                            <li><span class="color-white">Product</span> <span class="float-right color-black"><b>{{ planDetail.name }}</b></span> </li>
                            <li><span class="color-white">Payment Type</span> <span class="float-right color-black"><b>{{ planDetail.type }}</b></span> </li>
                            <li><span class="color-white">Qty</span> <span class="float-right color-black"><b>{{ qty }}</b></span> </li>
                            <li><span class="color-white">Discount</span> <span class="float-right color-black"><b>{{ formatCurrency(discount, planDetail.currencyCode) }}</b></span> </li>
                        </ul>
                        <hr>
                        <p>
                            <span class=""><b>Total</b></span> 
                            <span class="float-right color-black">
                                <b>{{ formatCurrency(total - discount, planDetail.currencyCode) }}</b>
                            </span>
                        </p>
                        <!--<div class="mb-3 text-center">
                            <button type="button" class="btn btn-dark">Switch to onetime</button>
                        </div>-->
                    </div>
                </div>
                <div class="col-md-6 block-2">
                    <h3 class="text-center text-danger" v-if="paymentError">Payment failed, please try again</h3>   
                    <div class="payment-method-div" v-if="!paymentSuccess">
                        
                        <span v-for="pm in sortArrays()" :key="pm.type">
                            <input v-if="pm.isHide == false" type="radio" class="btn-check" :value="pm.type" name="paymentType" v-model="paymentType" :id="pm.type" :disabled="pm.isDisabled" >
                            <label v-if="pm.isHide == false" class="btn btn-outline-success" :for="pm.type">
                                <i :class="pm.icon" v-if="pm.icon"></i>
                                <img :src="pm.imageName" v-if="pm.imageName"><br>
                                {{ pm.title }}
                            </label>&nbsp; 
                        </span>

                        <!--<input type="radio" class="btn-check" value="card" name="paymentType" v-model="paymentType" id="card" >
                        <label class="btn btn-outline-success" for="card"><i class="far fa-credit-card"></i><br>Card</label>&nbsp;

                        <input type="radio" class="btn-check" value="paypal" name="paymentType" v-model="paymentType" id="paypal" >
                        <label class="btn btn-outline-success" for="paypal"><i class="fab fa-paypal"></i><br>PayPal</label>&nbsp;

                        <input type="radio" class="btn-check" :disabled="buttonTypeName != 'GOOGLE_PAY'" value="gpay" name="paymentType" v-model="paymentType" id="gpay" >
                        <label class="btn btn-outline-success" for="gpay"><i class="fab fa-google-pay"></i><br>GPay</label>&nbsp;

                        <input type="radio" class="btn-check" :disabled="buttonTypeName != 'LINK'" value="linkPay" name="paymentType" v-model="paymentType" id="linkPay" >
                        <label class="btn btn-outline-success" for="linkPay"><img :src="linkLogo"><br>Link</label>&nbsp;

                        <input type="radio" class="btn-check" :disabled="buttonTypeName != 'APPLE_PAY'" value="applePay" name="paymentType" v-model="paymentType" id="applePay" >
                        <label class="btn btn-outline-success" for="applePay"><i class="fab fa-cc-apple-pay"></i><br>Apple Pay</label>&nbsp;-->

                        <!--<input type="radio" class="btn-check" :disabled="planDetail.type != 'one_time'" value="wechat_pay" name="paymentType" v-model="paymentType" id="wechat_pay">
                        <label class="btn btn-outline-success" for="wechat_pay"><img :src="wechatPayLogo"></label>&nbsp;
                        
                        <input type="radio" class="btn-check" :disabled="planDetail.type != 'one_time'" value="alipay" name="paymentType" v-model="paymentType" id="alipay">
                        <label class="btn btn-outline-success" for="alipay"><img :src="aliPayLogo"></label>&nbsp;-->
                    </div>
                    <form v-if="!paymentSuccess">
                        <div class="form-group row mb-2">
                            <label for="staticEmail" class="col-sm-2 col-form-label fw-bold">Email<span class="required"><b>*</b></span></label>
                            <div class="col-sm-10">
                                <input type="email" :class="errors.email ? 'input-invalid' : ''" class="form-control input-sm" v-model="email" @change="validateEmail()">
                            </div>
                        </div>
                        <div class="form-group row mb-2">
                            <label for="staticEmail" class="col-sm-2 col-form-label fw-bold">Password</label>
                            <div class="col-sm-10">
                                <input type="password" v-model="password" :class="errors.password ? 'input-invalid' : ''" class="form-control input-sm" :disabled="passwordDisabled" @change="validatePassword()">
                                <small class="text-danger"><b>{{ passwordHelpText }}</b></small>
                            </div>
                        </div>
                        <div class="form-group row mb-2" v-if="!updateSubscription">
                            <label for="staticEmail" class="col-sm-2 col-form-label fw-bold">Phone</label>
                            <div class="col-sm-10">
                                <vue-tel-input v-model="phone" :defaultCountry="44" mode="international"
                                :dropdownOptions="{ showDialCodeInSelection: true, showSearchBox: true, showFlags: true }">
                                    <template v-slot:arrow-icon>
                                        <span v-if="open"><i class="fa fa-up"></i> </span>
                                        <span v-else><i class="fa fa-arrow-down"></i> </span>
                                    </template>
                                </vue-tel-input>
                            </div>
                        </div>
                        <div class="form-group row mb-2">
                            <label for="staticEmail" class="col-sm-2 col-form-label fw-bold">{{ qtytest }}<span class="required"><b>*</b></span></label>
                            <div class="col-sm-10">
                                <input type="number" v-model="qty" :class="errors.qty ? 'input-invalid' : ''" class="form-control input-sm" min="1" @change="updateAmount()">
                                <small class="float-right" v-if="!haveCouponCode"><a href="javascript:void(0)" @click="haveCouponCode = true">Have coupon code?</a></small>
                            </div>
                        </div>
                        <div class="form-group row mb-2" v-if="!updateSubscription && haveCouponCode">
                            <label for="staticEmail" class="col-sm-2 col-form-label fw-bold">Coupon</label>
                            <div class="col-sm-10">
                                <div class="input-group w-auto">
                                    <input type="text" v-model="coupon" class="form-control input-sm" placeholder="Enter Coupon Code" />
                                    <button type="button" :disabled="isDisabled" class="btn btn-dark input-sm" data-mdb-ripple-color="dark" @click="applyCoupon()">
                                        Apply
                                    </button>
                                </div>
                                <output class="badge badge-success" v-if="discount > 0">{{ coupon }}</output>
                            </div>
                        </div>
                        <div id="card-section" class="form-group row mb-2" v-if="!updateSubscription">
                            <label for="staticEmail" class="col-sm-2 col-form-label fw-bold">Card<span class="required"><b>*</b></span></label>
                            <div class="col-sm-10">
                                <div ref="card"></div>
                            </div>
                        </div>
                        <div class="form-group row mb-2">
                            <label for="staticEmail" class="col-sm-2 col-form-label fw-bold">Captcha</label>
                            <div class="col-sm-10">
                                <vue-recaptcha ref="recaptcha" 
                                    :sitekey="siteKey"
                                    @verify="verifyCaptchaMethod"
                                    >
                                </vue-recaptcha>
                            </div>
                        </div>
                        <div class="mb-3 text-center">
                            <div class="form-group row mb-2">
                                <div class="col-sm-2 "></div>
                                <div class="col-sm-10">
                                    <button type="button" class="payment-btn" v-if="paymentType == 'card' || paymentType == 'paypal'" :disabled="isDisabled" @click="makePayment()">Pay {{ formatCurrency(total - discount, planDetail.currencyCode) }} <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></button>
                                    <button type="button" class="payment-btn" v-if="paymentType == 'gpay'" :disabled="isDisabled" @click="makePaymentThroughStripeButton()">
                                        <img :src="googleLogo" /> Pay {{ formatCurrency(total - discount, planDetail.currencyCode) }} 
                                        <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                                    </button>
                                    <button type="button" class="payment-btn" v-if="paymentType == 'applePay'" :disabled="isDisabled" @click="makePaymentThroughStripeButton()">
                                        <img :src="applePayLogo" /> Pay {{ formatCurrency(total - discount, planDetail.currencyCode) }} 
                                        <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                                    </button>
                                    <button type="button" class="payment-btn-link" v-if="paymentType == 'linkPay'" :disabled="isDisabled" @click="makePaymentThroughStripeButton()">
                                        <img :src="linkBigLogo" /> |  Pay {{ formatCurrency(total - discount, planDetail.currencyCode) }} 
                                        <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                                    </button>
                                    <button type="button" class="payment-btn" v-if="paymentType == 'alipay' || paymentType == 'wechat_pay'" :disabled="isDisabled" @click="makePayment()">
                                        Pay {{ formatCurrency(total - discount, planDetail.currencyCode) }} 
                                        <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                                    </button>
                                </div>
                            </div>
                            <!--<button type="button" class="btn btn-light" @click="$router.push({ path: '/'})" :style="{'float':'right'}">Back</button>-->
                            <div id="payment-request-button">
                                <!-- A Stripe Element will be inserted here. -->
                            </div>
                        </div>
                    </form>
                    
                    <div v-if="paymentSuccess">
                        <h3 class="text-center">Thank you for your payment</h3>
                        <!--<p class="text-center">Your order # is: 100000023</p>-->
                        <p class="text-center">You will receive an order confirmation email with details of your subscription. Please login to your account on <a href="https://app.packagedisabler.com/login">https://app.packagedisabler.com/login</a> to download your app.</p>
                        <div class="mb-3 text-center">
                            <a :href="loginUrl"><button type="button" class="btn btn-success">Login Page</button></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { VueRecaptcha } from 'vue-recaptcha';
import HomeDataService from "../services/HomeDataService";
import PaymentService from "../services/PaymentService";
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
const $toast = useToast();

const appearance = {
  theme: "night",
};
let stripe = Stripe(process.env.VUE_APP_STRIPE_PK),
  elements = stripe.elements(appearance),
  card = undefined, paymentRequest = undefined, prButton = undefined;

export default {
  components: { 
    VueRecaptcha,
    VueTelInput
  },
  props: {
    logo: {
      type: String,
      default: "./img/logo-light.png",
    },
    whatsappLogo: {
      type: String,
      default: "./img/whatsapp-50.png",
    },
    linkLogo: {
      type: String,
      default: "./img/link.ico",
    },
    googleLogo: {
        type: String,
        default: "./img/gpaylogo.ico",
    },
    applePayLogo: {
        type: String,
        default: "./img/applePay.ico",
    },
    linkBigLogo:{
        type: String,
        default: "./img/linkBig.ico",
    },
    wechatPayLogo: {
        type: String,
        default: "./img/wechatpay.ico",
    },
    aliPayLogo: {
        type: String,
        default: "./img/alipay-logo.ico",
    },
  },
  data() {
    return {
        isDisabled: false,
        passwordDisabled: true,
        priceId: this.$route.query.id,
        planDetail: "",
        total: 0,
        email: "",
        password: "",
        qty: 1,
        coupon: "",
        discount: 0,
        errors: {
            email: false,
            password: false,
            qty: false,
        },
        paymentSuccess: false,
        updateSubscription: false,
        siteKey: process.env.VUE_APP_NOCAPTCHA_SITEKEY,
        captcha: "",
        phone:"",
        loginUrl: process.env.VUE_APP_APP_URL,
        passwordHelpText:"Password only required for new account.",
        qtytest:"Qty",
        paymentType: "card",
        haveCouponCode: false,
        canMakePaymentAvailability: {
            "APPLE_PAY": null,
            "GOOGLE_PAY": null,
            "BROWSER": null,
            "LINK": true
        },
        buttonTypeName: "",
        subscriptionDetail: "",
        paymentError: false,
        paymentMethodOptions: [
            {
                id: 7,
                type: "alipay",
                title: "",
                icon: "",
                imageName: "./img/alipay-logo.ico",
                isDisabled: true,
                isHide: false,
            },
            {
                id: 6,
                type: "wechat_pay",
                title: "",
                icon: "",
                imageName: "./img/wechatpay.ico",
                isDisabled: true,
                isHide: false,
            },
            {
                id: 5,
                type: "linkPay",
                title: "Link",
                icon: "",
                imageName:"./img/link.ico",
                isDisabled: true,
                isHide: false,
            },
            {
                id: 4,
                type: "applePay",
                title: "Apple Pay",
                icon: "fab fa-cc-apple-pay",
                imageName:"",
                isDisabled: true,
                isHide: false,
            },
            {
                id: 3,
                type: "gpay",
                title: "GPay",
                icon: "fab fa-google-pay",
                imageName:"",
                isDisabled: true,
                isHide: false,
            },
            {
                id: 2,
                type: "paypal",
                title: "Paypal",
                icon: "fab fa-paypal",
                imageName:"",
                isDisabled: false,
                isHide: false,
            },
            {
                id: 1,
                type: "card",
                title: "Card",
                icon: "far fa-credit-card",
                imageName:"",
                isDisabled: false,
                isHide: false,
            },
        ]
    }
  },
  methods: {
    makePaymentByPaymentButton(paymentMethodDetail) {
        let context = this;
        context.isDisabled = true;
        let data = {
            token: "",
            qty: context.qty,
            couponCode: context.coupon,
            password: context.password,
            email: context.email,
            priceId: context.priceId,
            payment_method_id: paymentMethodDetail.paymentMethod.id,
            captcha: context.captcha,
            phone_number: context.phone,
        };
        PaymentService.makePayment(data)
            .then((response) => {
                stripe.confirmCardPayment(response.data.data, 
                {payment_method: paymentMethodDetail.paymentMethod.id},
                {handleActions: false}
            ).then(function (result) {
                    if (result.error) {
                        context.$refs.recaptcha.reset();
                        context.isDisabled = false;
                        $toast.error(result.error.message);
                    } else {
                        context.isDisabled = false;
                        // The payment has been processed!
                        if (result.paymentIntent.status === "succeeded") {
                            //console.log(result);
                            $toast.success(response.data.respones_msg);
                            context.paymentSuccess = true;
                        }
                    }
                });
            }).catch((e) => {
                context.$refs.recaptcha.reset();
                context.isDisabled = false;
                if (e.response) {
                    $toast.error(e.response.data.respones_msg);
                } else {
                    $toast.error(e);
                }
            });
    },
    validateEmail() {
        let data = { email: this.email, priceId: this.priceId };
        PaymentService.validateEmail(data)
        .then((response) => {
            if (response.data.data.passwordRequired == true) {
                this.passwordDisabled = false;
                this.errors.password = true; 
            } else {
                this.passwordDisabled = true;
            }
            this.passwordHelpText = "Password only required for new account."
            this.updateSubscription = response.data.data.updateSubscription;
            this.subscriptionDetail = response.data.data.subscriptionDetail;
            if (!this.updateSubscription && this.planDetail.identifier == "PCHAT") {
                this.qty = 2;
                this.updateAmount();
            }
            this.$nextTick(function () {
                if (!card) {
                    card = elements.create("card");
                    card.mount(this.$refs.card);
                } else {
                    card.mount(this.$refs.card);
                }
            })
            this.errors.email = false;
        }) .catch((e) => {
            this.errors.email = true;
            this.updateSubscription = e.response.data.data.updateSubscription;
            if (!this.updateSubscription) {
                this.$nextTick(function () {
                    if (!card) {
                        card = elements.create("card");
                        card.mount(this.$refs.card);
                    } else {
                        card.mount(this.$refs.card);
                    }
                })
            } else {
                this.passwordDisabled = false;
                this.passwordHelpText = "Account exist, enter the password and verify it to update your subscription."
                this.subscriptionDetail = e.response.data.data.subscriptionDetail;
            }
            $toast.error(e.response.data.respones_msg);
        });
    },

    validatePassword() {
        this.errors.password = false; 
    },
    makePayment() {
        if (this.planDetail.identifier == "PCHAT" && this.qty < 2) {
            $toast.error("Please purchase minimum 2 license for 2 users.");
            return;  
        }
        if (!this.captcha) {
            $toast.error("Please verify captcha.");
            return;  
        }
        let context = this;
        context.isDisabled = true;
        if (this.updateSubscription) {
            let data = {
                qty: context.qty,
                email: context.email,
                password: context.password,
                priceId: context.priceId,
                captcha: context.captcha,
            };
            PaymentService.updateSubscription(data)
            .then((response) => {
                let confirmPaymentMethod = stripe.confirmCardPayment(response.data.data);
                if (this.subscriptionDetail && this.subscriptionDetail.paymentMethod) {
                    if (this.subscriptionDetail.paymentMethod.type == 'paypal') {
                        confirmPaymentMethod = stripe.confirmPayPalPayment(response.data.data, {
                            return_url: window.location.href,
                        })
                    }
                }
                confirmPaymentMethod.then(function (result) {
                    if (result.error) {
                        context.$refs.recaptcha.reset();
                        context.isDisabled = false;
                        $toast.error(result.error.message);
                    } else {
                        context.isDisabled = false;
                        // The payment has been processed!
                        if (result.paymentIntent.status === "succeeded") {
                            $toast.success(response.data.respones_msg);
                            context.paymentSuccess = true;
                        }
                    }
                });
            }).catch((e) => {
                context.$refs.recaptcha.reset();
                $toast.error(e.response.data.respones_msg);
                context.isDisabled = false;
            });
            return;
        }
        
        if (!this.email) {
            this.errors.email = true; 
        }
        if (!this.qty) {
            this.errors.qty = true; 
        }

        /*stripe.createToken(card).then(function (result) {
            if (result.error) {
                self.hasCardErrors = true;
                context.isDisabled = false;
                //self.$forceUpdate(); // Forcing the DOM to update so the Stripe Element can update.
                $toast.error(result.error.message);
                this.$refs.recaptcha.reset();
                return;
            }*/
            let token = ""; //result.token;

            let paymentMethodObject;
            if (context.paymentType == "paypal") {
                paymentMethodObject = {
                    type: "paypal",
                }   
            }else if (context.paymentType == "wechat_pay") {
                paymentMethodObject = {
                    type: "wechat_pay",
                }   
            }else if (context.paymentType == "alipay") {
                paymentMethodObject = {
                    type: "alipay",
                }   
            } else {
                paymentMethodObject = {
                    type: "card",
                    card: card,
                    billing_details: {
                    name: context.email,
                    email:context.email,
                    },
                }
            }
            stripe.createPaymentMethod(paymentMethodObject).then(function (result) {
                let data = {
                    token: token,
                    qty: context.qty,
                    couponCode: context.coupon,
                    password: context.password,
                    email: context.email,
                    priceId: context.priceId,
                    payment_method_id: result.paymentMethod.id,
                    captcha: context.captcha,
                    phone_number: context.phone,
                    paymentType: context.paymentType
                };

                PaymentService.makePayment(data)
                .then((response) => {
                    let confirmPaymentMethod;
                    if ((context.total - context.discount) == 0) {
                        $toast.success(response.data.respones_msg);
                        card.clear();
                        context.paymentSuccess = true;
                        return;   
                    }
                    if (context.paymentType == "paypal") {
                        confirmPaymentMethod = stripe.confirmPayPalPayment(response.data.data, {
                            return_url: window.location.href,
                        })
                    } else if (context.paymentType == "wechat_pay") {
                        confirmPaymentMethod = stripe.confirmWechatPayPayment(response.data.data, {
                            payment_method_options: {
                                wechat_pay: {
                                    client: 'web'
                                }
                            }
                        })
                    } else if (context.paymentType == "alipay") {
                        confirmPaymentMethod = stripe.confirmAlipayPayment(response.data.data, {
                            return_url: window.location.href,
                        })
                    } else {
                        confirmPaymentMethod = stripe.confirmCardPayment(response.data.data, {
                            payment_method: {
                            card: card,
                            billing_details: {
                                name: context.email,
                            },
                            },
                        })
                    }
                    confirmPaymentMethod.then(function (result) {
                        if (result.error) {
                            context.$refs.recaptcha.reset();
                            context.isDisabled = false;
                            $toast.error(result.error.message);
                            let data = {
                                reqBody: result,
                            };

                            PaymentService.logPaymentError(data)
                            .then((response) => {

                            }).catch((e) => {
                                $toast.error(e.response.data.respones_msg);  
                            })
                        } else {
                            context.isDisabled = false;
                            // The payment has been processed!
                            if (result.paymentIntent.status === "succeeded") {
                                //console.log(result);
                                $toast.success(response.data.respones_msg);
                                card.clear();
                                context.paymentSuccess = true;
                            }
                        }
                    });
                })
                .catch((e) => {
                    context.$refs.recaptcha.reset();
                    context.isDisabled = false;
                    if (e.response) {
                        $toast.error(e.response.data.respones_msg);
                    } else {
                        $toast.error(e);
                    }
                });
            });
      //});
    },

    priceDetail() {
        let data = { id: this.priceId }
        PaymentService.priceDetail(data)
        .then((response) => {
            this.planDetail = response.data.data;
            if (this.planDetail.identifier == "PCHAT") {
                this.qtytest = "Total Users"    
            }
            this.total = this.planDetail.amount
            if (this.planDetail.type == "one_time") {
                this.paymentMethodOptions[0].isDisabled = false;
                this.paymentMethodOptions[1].isDisabled = false;
            }
            if (this.planDetail.countryCurrency && this.planDetail.countryCurrency.discountPercentage) {
                this.discount = (this.total * (this.planDetail.countryCurrency.discountPercentage / 100))
                this.coupon = this.planDetail.countryCurrency.couponCode;
                this.haveCouponCode = true;
            }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    updateAmount() {
        if (this.qty <= 0) {
            this.qty = 1;
        }
        this.total = this.planDetail.amount * this.qty;
    },

    applyCoupon() {
        this.discount = 0;
        this.isDisabled = true;
        let data = { couponCode: this.coupon, priceId: this.priceId, qty: this.qty }
        PaymentService.applyCoupon(data)
        .then((response) => {
            this.isDisabled = false;
            this.discount = response.data.data;
            $toast.success(response.data.respones_msg);
        })
        .catch((e) => {
            this.isDisabled = false;
            $toast.error(e.response.data.respones_msg);
        });
    },

    formatCurrency(amount, currencyCode = "GBP") {
        let formatter = new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 2
        })
        return formatter.format(amount);
    },

    verifyCaptchaMethod(recaptchaToken) {
      this.captcha = recaptchaToken;
    },
    makePaymentThroughStripeButton() {
        if (!this.captcha) {
            $toast.error("Please verify captcha.");
            return;  
        }
        
        if (this.updateSubscription) {
            let context = this;
            context.isDisabled = true;
            let data = {
                qty: this.qty,
                email: this.email,
                password: this.password,
                priceId: this.priceId,
                captcha: this.captcha,
            };
            PaymentService.updateSubscription(data)
            .then((response) => {
                stripe
                .confirmCardPayment(response.data.data)
                .then(function (result) {
                    if (result.error) {
                        context.$refs.recaptcha.reset();
                        context.isDisabled = false;
                        $toast.error(result.error.message);
                    } else {
                        context.isDisabled = false;
                        // The payment has been processed!
                        if (result.paymentIntent.status === "succeeded") {
                            $toast.success(response.data.respones_msg);
                            context.paymentSuccess = true;
                        }
                    }
                });
            }).catch((e) => {
                context.$refs.recaptcha.reset();
                $toast.error(e.response.data.respones_msg);
                context.isDisabled = false;
            });
            return;
        } else {
            if (!this.email) {
                this.errors.email = true; 
            }
            if (!this.qty || this.qty <= 0) {
                this.errors.qty = true; 
            }
            if (this.passwordDisabled == false && !this.password) {
                this.errors.password = true; 
            } else {
                this.errors.password = false; 
            } 
            if (this.errors.email == true || this.errors.qty == true || this.errors.password == true) {
                $toast.error("Please enter value in required fields.");
                return;
            }
            if (this.planDetail.identifier == "PCHAT" && this.qty < 2) {
                $toast.error("Please purchase minimum 2 license for 2 users.");
                return;  
            }
            paymentRequest.show();
        }
    },
    sortArrays() {
        return this.paymentMethodOptions.slice().sort(function(a, b){
            return (a.isDisabled > b.isDisabled) ? 1 : -1;
        });
    },
    createPageViewLog() {
        let data =  { page: "Payment", pageData: { priceId: this.priceId } }
        HomeDataService.createPageViewLog(data)
        .then((response) => {
            //$toast.success(response.data.respones_msg);
        }).catch((e) => {
            $toast.error(e.response.data.respones_msg);
        });
    },
  },
  mounted: function () {
    if (this.paymentSuccess == false) {
        if (!card) {
            card = elements.create("card");
            card.mount(this.$refs.card);
        } else {
            card.mount(this.$refs.card);
        }
    }
  },
  beforeMount() {
    this.priceDetail();
    this.createPageViewLog();
    if (this.$route.query.redirect_status == "succeeded") {
        this.paymentSuccess = true;
    }
    if (this.$route.query.redirect_status == "failed") {
        this.paymentError = true;
    }
  },
  watch: {
    paymentType() {
        if (this.paymentType != "card") {
            document.getElementById('card-section').style.display = 'none';
        } else {
            document.getElementById('card-section').style.display = '';
        }
    },
    async total() {
        if (paymentRequest) {
            paymentRequest.update({
                country: 'GB',
                currency: 'gbp',
                total: {
                    label: 'Demo total',
                    amount: (this.total - this.discount) * 100,
                },
            })
        } else {
            paymentRequest = stripe.paymentRequest({
                country: 'GB',
                currency: 'gbp',
                total: {
                    label: 'Demo total',
                    amount: (this.total - this.discount) * 100,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });
            
            prButton = elements.create('paymentRequestButton', {
                paymentRequest,
            });
            const result = await paymentRequest.canMakePayment();
            if (result) {                
                this.canMakePaymentAvailability = await paymentRequest._canMakePaymentAvailability;
                this.buttonTypeName = await paymentRequest._buttonTypeName;
                //console.log(this.paymentMethodOptions)
                if (this.buttonTypeName == "APPLE_PAY") {
                    this.paymentMethodOptions[3].isDisabled = false;
                } else if (this.buttonTypeName == "LINK") {
                    this.paymentMethodOptions[2].isDisabled = false;
                } else if (this.buttonTypeName == "GOOGLE_PAY") {
                    this.paymentMethodOptions[4].isDisabled = false;
                }
                //prButton.mount('#payment-request-button');
            } else {
                document.getElementById('payment-request-button').style.display = 'none';
            }
        }
        prButton.on('click', function(event) {
            //alert(1212);    
        });
        paymentRequest.on('paymentmethod', async (ev) => {
            //this.makePaymentByPaymentButton(ev);
            let paymentMethodDetail = ev;
            let context = this;
            context.isDisabled = true;
            let data = {
                token: "",
                qty: context.qty,
                couponCode: context.coupon,
                password: context.password,
                email: context.email,
                priceId: context.priceId,
                payment_method_id: paymentMethodDetail.paymentMethod.id,
                captcha: context.captcha,
                phone_number: context.phone,
            };
            PaymentService.makePayment(data)
                .then((response) => {
                    const clientSecret = response.data.data;
                    stripe.confirmCardPayment(response.data.data, 
                        {payment_method: paymentMethodDetail.paymentMethod.id},
                        {handleActions: false}
                    ).then(function (result) {
                            if (result.error) {
                                context.$refs.recaptcha.reset();
                                context.isDisabled = false;
                                $toast.error(result.error.message);
                                ev.complete('fail');
                            } else {
                                context.isDisabled = false;
                                // The payment has been processed!
                                if (result.paymentIntent.status === "succeeded") {
                                    $toast.success(response.data.respones_msg);
                                    context.paymentSuccess = true;
                                    ev.complete('success');
                                }
                                 if (result.paymentIntent.status === "requires_action") {
                                    // Let Stripe.js handle the rest of the payment flow.
                                    stripe.confirmCardPayment(clientSecret)
                                    .then(function (result) {
                                        if (result.error) {
                                            context.$refs.recaptcha.reset();
                                            context.isDisabled = false;
                                            $toast.error(result.error.message);
                                            ev.complete('fail');   
                                        } else {
                                            $toast.success(response.data.respones_msg);
                                            context.paymentSuccess = true;
                                            ev.complete('success');
                                        }
                                    })
                                }
                            }
                        });
                }).catch((e) => {
                    context.$refs.recaptcha.reset();
                    context.isDisabled = false;
                    if (e.response) {
                        $toast.error(e.response.data.respones_msg);
                    } else {
                        $toast.error(e);
                    }
                    ev.complete('fail');
                });
        })
    }
  }
}
</script>

<style>

@media screen and (min-width: 600px) {
    .set-bg {
        padding: 5px;
        background-color: #f6f7f8 /* #FFCCBC */;
    }
    .container-fluid1 {
        margin-top: 30px;
        padding: 5px;
    }
}

.block-1 {
    background-color: #527eee;
    padding: 20px;
}
.block-2 {
    background-color: var(--md-theme-default-background,#fff);
    padding: 20px;
}
.float-right{
  float: right;
}
.color-white{
  color: var(--md-theme-default-background, #fff);
}
.color-black{
  color: black;
}
.input-sm{
    height: 35px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

/*streip css*/
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/*stripe css end*/

.input-invalid {
    border-color: #fa755a;
}


element.style {
}
.badge-success {
    color: #fff;
    background-color: #28a745;
}
.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}
.required {
    content:"*";
    color:red;
}
</style>